import bootstrap from '../node_modules/bootstrap/dist/js/bootstrap';
// const bootstrap = require('bootstrap')  


import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Swal from 'sweetalert2';
window.Swal = Swal;


import "bootstrap"

import '../src/scss/custom.scss';